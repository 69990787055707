import Home from "./Pages/Home"
import './components/Upper/upper.css'
import './components/Navbar/navbar.css'
import './components/Header/header.css'
import './components/About/about.css'
import './components/Footer/footer.css'
import './components/Questions/question.css'
import './components/Design/design.css'
import './components/Animation/animation.css'
import'./components/Opinion/opinion.css'
import './components/Services/sevices.css'
import './components/Feedback/feedback.css'
import './components/Ourteam/ourteam.css'
import './components/Workstyle/workstyle.css'
import './components/colleagues/colleagues.css'
import './components/Portfolio/portfolio.css'
import './components/Navbar/mob.css'
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import PortfolioPage from "./Pages/PortfolioPage";
import MobileNav from "./components/Navbar/MobileNav";
import { useEffect } from "react"

import './App.css'
function App() {

  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handler);

    return () => {
      document.removeEventListener("contextmenu", handler);
    };
  }, []);

  return (
    <div >
     <Router>
      <Routes>
        <Route index path="/" element={<Home />}></Route>
        {/* <Route exact path="/" element={<Home />}></Route> */}

        <Route  path="home" element={<Home />}></Route>
        {/* <Route  path="/home" element={<Home />}></Route> */}

       

        <Route path="portfolio" element={<PortfolioPage />}></Route>

        {/* <Route path="/portfolio" element={<PortfolioPage />}></Route> */}

      </Routes>
    </Router>
    </div>
  );
}

export default App;
