import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { HashLink } from 'react-router-hash-link'

const animation = {
  hidden: {
    x: 100,
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

function Navbar() {
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  useEffect(() => {
    localStorage.setItem("language", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    
  };

  const [click, setClick] = useState(false);

  const closeMenu = () => setClick(false);
  return (
    <motion.div
      className="navbar"
      initial="hidden"
      whileInView="visible"
      custom={2}
      variants={animation}
    >
      <input id="toggle" type="checkbox"></input>

      <div className="nav-media">
        <div className="nav-wrapper">
          <ul>
            <li>
             <HashLink to="/home/#services"> {t("service")}</HashLink>
             
            </li>
    
            <li>
            <Link to="/portfolio"> {t("portfolio")}</Link>
            </li>
            <li>
              <HashLink  to="/home/#about">  {t("about")} </HashLink >
            </li>
            <li>
              <HashLink to="/home/#feedback">{t("contact")}</HashLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="contnav">
      <div className="nav">
        <ul>
          <li>
          <HashLink  to="/home/#services" onClick={closeMenu}>
         
              {t("service")}
            </HashLink >
          </li>

          <li>
          <Link to="/portfolio"> {t("portfolio")}</Link>
          </li>

          <li>
           
            <HashLink to="/home/#about" onClick={closeMenu}>
              {t("about")}
            </HashLink>

          </li>

          <li>
            <HashLink to="/home/#feedback" onClick={closeMenu}>
              
              {t("contact")}
            </HashLink>
          </li>
        </ul>
      </div>
      <div className="languages">
        <ul>
          {/* <img src="/images/ket kaput.svg"></img> */}

          {/* <img src="/images/ket kaput.svg"></img> */}
          <svg className={selectedLanguage === "en" ? "lang-li-active" : "lang-li"} width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="4" cy="4.5" r="4" fill="#1B7F9D"/>
</svg>
          <li
           className={selectedLanguage === "en" ? "lang-li-active" : "lang-li"}
            onClick={() => changeLanguage("en")}
          >
            ENG
          </li>
          
          {/* <img src="/images/ket kaput.svg"></img> */}

          <svg className={selectedLanguage === "ru" ? "lang-li-active" : "lang-li"} width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
             <circle cx="4" cy="4.5" r="4" fill="#1B7F9D"/>
          </svg>

          <li
            className={selectedLanguage === "ru" ? "lang-li-active" : "lang-li"}
            onClick={() => changeLanguage("ru")}
          >
            RUS
          </li>

          <svg className={selectedLanguage === "hy" ? "lang-li-active" : "lang-li"} width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="4" cy="4.5" r="4" fill="#1B7F9D"/>
</svg>
          <li
           className={selectedLanguage === "hy" ? "lang-li-active" : "lang-li"}
            onClick={() => changeLanguage("hy")}
          >
            ARM
          </li>


        </ul>
      </div>
     </div>
    </motion.div>
  );
}

export default Navbar;
