export const service = [
  {
    id: 0,
    title_en: "Web Design",
    title_ru: "Веб-дизайн",
    title_hy: "Վեբ դիզայն",
    description_hy:
    "  Մենք առաջարկում ենք գրավիչ դիզայն Ձեր բիզնես կայքերի համար: (օրինակներ) ",
    description_ru:
    "Внешний дизайн сайта создает у посетителей первое впечатление, по которому у них формируется мнение о ваших способностях и авторитетности.Благодаря этому сервису вы сможете создать привлекательный для посетителей и вас сайт, с понятным и оптимизированным внешним видом, который никого не смутит.",
    description_en:
    " We offer an attractive design for your business websites.",
  },
  {
    id: 1,
    title_en: "Website Design",
    title_ru: "Дизайн Сайта",
    title_hy: "Վեբ կայքերի ձևավորում",
    description_hy:
    " Այս ծառայությունը նախատեսված է ժամանակակից չափանիշներին համապատասխան, դինամիկ ֆունկցիոնալությամբ տարբերվող, եզակի դիզայնով և հեշտ օգտագործման  կայքերի ստեղծման համար: ",
    description_ru:
    "Данная услуга предназначена для создания веб-сайтов, отвечающих современным стандартам, обладающих динамичным функционалом, адаптивным дизайном и простых в использовании.",
    description_en:
    "  This service is intended for the creation of websites that meet modern standards, dynamic functionality, adaptive design, and are easy to use.",
  },
  {
    id: 2,
    title_en: "Website Maintenance",
    title_ru: "Обслуживание веб-сайта",
    title_hy: "Կայքերի սպասարկում",
    description_hy:
    " Մենք առաջարկում ենք տեխնիկական սպասարկման ծառայություններ ՝բրաուզերի համատեղելիության փորձարկում, ծրագրային ապահովման թարմացումներ,կայքի արագության փորձարկում, վերլուծություն, դոմեյնի և հոսթինգի հետ կապված խնդիրների լուծում և այլն:    ",
    description_ru:
    " Обслуживание веб-сайта включает в себя ряд мероприятий, обеспечивающих точность веб-сайта, обновления и другие сопутствующие услуги.",
    description_en:
    "We offer web maintenance services such as browser Compatibility Testing, Software updates, Website Backups, Site Speed testing, Analytics, domain, and hosting, etc. ",
  },

    {
    id: 3,
    title_en: "App Creation",
    title_ru: "Создание приложений",
    title_hy: "Հավելվածների ստեղծում",
    description_hy:
    " Մենք նախագծում և ծրագրավորում ենք օգտատերերի համար հարմար բջջային հավելվածներ Android, ինչպես նաև iOS համակարգերի համար:",
    description_ru:
    "Мы также разрабатываем и создаем удобные и целевые мобильные приложения для систем Android и iOS.",
    description_en:
    "We design and build user-friendly Mobile Apps for Android as well as iOS systems.",
  },
];

export const service1 = [
  {
    id: 0,
    title_en: "Social Media Marketing",
    title_ru: "Маркетинг в области СМИ",
    title_hy: "Սմմ",
    description_hy:
    "Մենք իրականացնում ենք ՍՄՄ ծառայություններ, ինչպիսիք են սոցիալական մեդիայի էջերի ստեղծում, վարում  և կառավարում, թիրախավորում, գովազդի միացում, կառավարում և այլն: ",
    description_ru:
    "Услуги SMM - Целевое управление и создание страниц в социальных сетях. ",
    description_en:
    " We realize a wide range of SMM services, such as the creation and management of social media pages, targeting, advertisement, etc. ",
  },
  {
    id: 1,
    title_en: "Content Marketing",
    title_ru: "Контент-маркетинг",
    title_hy: "Կոնտենտ մարքեթինգ",
    description_hy:
    "Մեր փորձառու քոփիռայթեռները ստեղծում են ՍԵՈ-ի վրա հիմնված եզակի բովանդակություն/կոնտենտ Ձեր վեբ նախագծերի համար: ",
    description_ru:
    "Копирайтинговые услуги - Разработка и создание текстовых статей (контента) веб-сайтов.",
    description_en:
    "Our experienced copywriters create unique SEO-based content for your web projects.",
  },
  {
    id: 2,
    title_en: "SEO",
    title_ru: "SEO-продвижение сайта",
    title_hy: "ՍԵՈ",
    description_hy:
    "Մեր ՍԵՈ մասնագետները անթերի են որոնողական համակարգերի օպտիմալացման ծառայություններ մատուցելիս. դրանք են մետա նկարագրությունների թարմացումը, տեխնիկական ՍԵՈ, լինքբիլդինգ ծառայություններ, թվային փիառ և այլն:",
    description_ru:
    "SEO-оптимизация - продвижение и оптимизация сайта в поисковых системах.",
    description_en:
    "Our SEO experts are flawless at delivering optimization services such as updating meta descriptions. They also offer technical SEO, link-building services, and digital PR.",
  },
  {
    id: 3,
    title_en: "Business Automation",
    title_ru: "Автоматизация бизнеса",
    title_hy: "Բիզնեսի Ավտոմատացում",
    description_hy:
    " Բիզնեսի ավտոմատացումը տալիս է անհամեմատելի հնարավորություններ բիզնեսի կառավարման, մարքեթինգի և հաճախորդների հետ շփման գործընթացներում CRM համակարգերի շնորհիվ։  ",
    description_ru:
    "лучшие варианты веб-дизайна",
    description_en:
    " Business automation provides incomparable opportunities in business management, marketing and customer contact processes thanks to CRM systems. Small, start-up businesses can also use this service.Among its advantages are cost reduction, improvement of service quality, sales planning, control, etc.",
  },
];

export const service2 = [
  {
    id: 0,
    title_en: "Logo creation",
    title_ru: "Изготовление логотипов",
    title_hy: "Լոգոների ստեղծում",
    description_hy:
    "Մենք ստեղծում ենք լոգոներ, որոնք վերաբերում են բրենդինգ և ինքնության ռազմավարությանը: Մեր լոգոները  առանձնանում են իրենց եզակի դիզայնով:",
    description_ru:
    "Созданием логотипа могут заниматься профессиональные графические дизайнеры, специализирующиеся на брендинге и дизайне фирменного стиля.",
    description_en:
    "We create logos that are about branding and identity. Our logos are one of a kind with a touch of Art.",
  },
  {
    id: 1,
    title_en: "Business Cards",
    title_ru: "Дизайн визитной карточки",
    title_hy: "Այցեքարտերի ստեղծում",
    description_hy:
    "Մենք առաջարկում ենք բարձրորակ այցեքարտերի դիզայնի մշակում, որոնք համապատասխանում են ապրանքանիշների ինքնության բարձր նշաձողին: ",
    description_ru:
    "Профессиональные графические дизайнеры или программные инструменты для дизайна обычно используются для создания высококачественных дизайнов визитных карточек, которые соответствуют общей визуальной идентичности бренда.",
    description_en:
    "We proudly offer high-quality Business Card Designs and that align with the brand's overall visual identity.",
  },
  {
    id: 2,
    title_en: "Booklet Design",
    title_ru: "Дизайн и верстка буклета",
    title_hy: "Բուկլետների ձևավորում ",
    description_hy:
    "Մեր գրաֆիկական դիզայներները հանդես կգան նորարարական և կրեատիվ գաղափարներով ձեր բիզնեսի համար առավել անհրաժեշտ բուկլետներ նախագծելիս:",
    description_ru:
    "Буклеты обычно используются для различных целей, таких как информационные брошюры, программы мероприятий, каталоги продукции, учебные пособия и т. д.",
    description_en:
    "Our graphic designers will come up with innovative and creative ideas when designing the brochures most needed for your business.",
  },
  {
    id: 3,
   
    title_en: "Flyer Design",
    title_ru: "Дизайн и верстка флаера",
    title_hy: "Ֆլայերների ձևավորում",
    description_hy:
      "Մենք առաջարկում ենք տպավորիչ դիզայնով ֆլայերներ, որոնք կարող են անմիջապես գրավել ձեր թիրախային լսարանի ուշադրությունը:",
    description_ru:
    "Флаеры должны быть привлекательными и информативными, привлекая внимание вашей целевой аудитории и побуждая их к действию.",
    description_en:
    "We offer eye-catching flyers, that can grab the attention of your target audience immediately. ",
  },
];